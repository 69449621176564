@media (max-width: 320px + 50px) {
  .media-test {
    display: 'xs';
  }
}
@media (max-width: 544px) {
  .media-test {
    display: 'mobile';
  }
}
@media (max-width: 864px) {
  .media-test {
    display: 'tabletSmall';
  }
}
@media (max-width: 1030px) {
  .media-test {
    display: 'tablet';
  }
}
@media (max-width: 1233px) {
  .media-test {
    display: 'desktopSmall';
  }
}
@media (max-width: 1643px) {
  .media-test {
    display: 'desktop';
  }
}
@font-face {
  font-family: 'Roboto';
  src: url('/fonts/Roboto-Regular.woff2') format('woff2'), url('/fonts/Roboto-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url('/fonts/Roboto-Bold.woff2') format('woff2'), url('/fonts/Roboto-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
.icon {
  display: block;
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.icon_center {
  margin-left: auto;
  margin-right: auto;
}
.icon_success {
  background-image: url("/img/icons/success.svg");
}
.icon_error {
  background-image: url("/img/icons/error.svg");
}
