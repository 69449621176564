@import "../app";

.icon {
    display: block;
    width: 50px;
    height: 50px;
    .contain();

    &_center {
        margin-left: auto;
        margin-right: auto;
    }

    &_success {
        .image('icons/success.svg');
    }

    &_error {
        .image('icons/error.svg');
    }
}
