@media (max-width: 320px + 50px) {
  .media-test {
    display: 'xs';
  }
}
@media (max-width: 544px) {
  .media-test {
    display: 'mobile';
  }
}
@media (max-width: 864px) {
  .media-test {
    display: 'tabletSmall';
  }
}
@media (max-width: 1030px) {
  .media-test {
    display: 'tablet';
  }
}
@media (max-width: 1233px) {
  .media-test {
    display: 'desktopSmall';
  }
}
@media (max-width: 1643px) {
  .media-test {
    display: 'desktop';
  }
}
@font-face {
  font-family: 'Roboto';
  src: url('/fonts/Roboto-Regular.woff2') format('woff2'), url('/fonts/Roboto-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url('/fonts/Roboto-Bold.woff2') format('woff2'), url('/fonts/Roboto-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
.player {
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 30px;
  width: 100%;
  padding: 10px;
  padding-right: 20px;
  margin-top: 10px;
  transition: background-color 0.2s;
  background: rgba(0, 0, 0, 0.1);
}
@media (min-width: 864px) {
  .player {
    padding-right: 60px;
  }
}
.player__play {
  margin-right: 16px;
  display: block;
  width: 40px;
  height: 40px;
  border: none;
  background-color: transparent;
  font-size: 0;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url("/img/player/play.svg");
  cursor: pointer;
  border-radius: 50%;
}
.player__play_active {
  background-image: url("/img/player/pause.svg");
}
.player__progress {
  position: relative;
  width: 100%;
  height: 3px;
  border-radius: 3px;
}
.player__rewind {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 4px;
  z-index: 2;
  cursor: auto;
  user-select: none;
  pointer-events: none;
  overflow: hidden;
  border-radius: 3px;
  /*
         * 1. Set to 0 width and remove border for a slider without a thumb
         */
}
.player__rewind::-webkit-slider-runnable-track {
  background: #E9E9E9;
}
.player__rewind::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 0;
  /* 1 */
  height: 4px;
  background: #fff;
  box-shadow: -100vw 0 0 100vw #1EBDD3;
  border: none;
  /* 1 */
}
.player__rewind::-moz-range-track {
  height: 40px;
  background: #E9E9E9;
}
.player__rewind::-moz-range-thumb {
  background: #fff;
  height: 0;
  width: 20px;
  border: none;
  border-radius: 0 !important;
  box-sizing: border-box;
  box-shadow: -100vw 0 0 100vw #1EBDD3;
}
.player__rewind::-ms-fill-lower {
  background: #1EBDD3;
}
.player__rewind::-ms-thumb {
  background: #fff;
  border: none;
  height: 4px;
  width: 0;
  box-sizing: border-box;
}
.player__rewind::-ms-ticks-after {
  display: none;
}
.player__rewind::-ms-ticks-before {
  display: none;
}
.player__rewind::-ms-track {
  background: #E9E9E9;
  color: transparent;
  height: 4px;
  border: none;
}
.player__rewind::-ms-tooltip {
  display: none;
}
.player__volume {
  display: none;
  position: absolute;
  right: 21px;
  bottom: 18px;
  height: 27px;
  width: 27px;
  border: 1px solid #CBCBCB;
  border-radius: 5px;
  opacity: .5;
  transition: height 0.2s, background-color 0.2s, border-color 0.2s, opacity 0.2s;
}
.player__volume:hover {
  height: 95px;
  background-color: white;
  border: 1px solid #CBCBCB;
}
@media (min-width: 864px) {
  .player__volume {
    display: block;
  }
}
.player:hover .player__volume {
  opacity: 1;
}
.player__button {
  left: 0;
  bottom: 0;
  display: block;
  border: none;
  font-size: 0;
  background-color: transparent;
  cursor: pointer;
  flex-shrink: 0;
  position: absolute;
  padding: 0;
  width: 25px;
  height: 25px;
  background-position: center;
  background-size: 15px;
  background-repeat: no-repeat;
  transition: opacity .2s;
  background-image: url("/img/player/volume-full-icon.svg");
}
.player__button:hover {
  opacity: .8;
}
.player__button_medium {
  background-image: url("/img/player/volume-medium-icon.svg");
}
.player__button_mute {
  background-image: url("/img/player/volume-no-icon.svg");
}
.player__bar {
  position: relative;
  bottom: 0;
  left: 0;
  overflow: hidden;
  height: 0;
  transition: height .2s;
}
.player__volume:hover .player__bar {
  height: 64px;
}
.player__fill {
  background: #F4F4F4;
  border-radius: 2px;
  width: 3px;
  height: 56px;
  position: absolute;
  bottom: 0;
  left: 11px;
}
.player__fill_highlight {
  width: 100%;
  left: 0;
  background: #1EBDD3;
}
.player__controller {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  writing-mode: bt-lr;
  /* IE */
  -webkit-appearance: slider-vertical;
  /* WebKit */
  opacity: 0;
  cursor: pointer;
}
