@import '../app';

.tabs
{
    position: relative;

    max-width: 425px;
    margin-top: 14px;
    margin-right: auto;
    margin-left: auto;

    &__label
    {
        font-size: 10px;
        line-height: 10px;

        display: block;

        margin-bottom: 5px;
        padding-left: 20px;

        opacity: .7;
        color: white;

        .sans-serif();
    }

    &__inner
    {
        display: flex;

        height: 45px;

        color: white;
        border: 1px solid rgba(255, 255, 255, .3);
        border-radius: 5px;
        background-color: transparent;
    }

    &_error &__inner {
        border: 1px solid @error;
    }

    &__input:checked + &__item
    {
        background-color: rgba(255, 255, 255, .3) !important;
    }

    &__input
    {
        position: absolute;
        left: -9999px;
    }

    &__item
    {
        display: flex;
        overflow: hidden;
        flex: 1;
        flex-direction: column;
        justify-content: center;

        box-sizing: border-box;
        padding: 0 10px;

        cursor: pointer;
        transition: background-color .3s;
        text-align: center;

        border-right: 1px solid rgba(255, 255, 255, .3);
        background-color: transparent;

        &:last-child
        {
            border-right: none;
        }
    }
}
