@import "../app";

.auth {

    &__form {
        padding-top: 10px;
    }

    &__button {
        margin-top: 10px;

        &_main {
            margin-top: 30px;
        }

        &_send {
            margin-top: 60px;
        }

        &_save {
            min-width: 150px;
            margin-left: 10px;
            margin-right: 10px;
        }

        &_test {
            margin-top: 80px;
            min-width: 280px;
            margin-left: 10px;
            margin-right: 10px;
        }
    }

    &__save {
        text-align: center;
    }

    &__input {
        margin-top: 25px;

        &_agree {
            margin-top: 20px;
            font-size: 13px;
        }
    }

    &__text {
        &_reset {
            margin-top: 20px;
        }

        &_thanks {
            margin-top: 20px;
            font-size: 14px;
            line-height: 20px;
        }
    }

    &__title {
        &_thanks {
            margin-top: 20px;
        }

        &_section {
            margin-top: 20px;
            max-width: 425px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    &__line {
        margin-top: 30px;

        .mobileBack({
            margin-top: 50px;
        });

        &_first {
            margin-top: 20px;
        }
    }

    &__set {
        margin-top: 40px;
    }

}
