@media (max-width: 320px + 50px) {
  .media-test {
    display: 'xs';
  }
}
@media (max-width: 544px) {
  .media-test {
    display: 'mobile';
  }
}
@media (max-width: 864px) {
  .media-test {
    display: 'tabletSmall';
  }
}
@media (max-width: 1030px) {
  .media-test {
    display: 'tablet';
  }
}
@media (max-width: 1233px) {
  .media-test {
    display: 'desktopSmall';
  }
}
@media (max-width: 1643px) {
  .media-test {
    display: 'desktop';
  }
}
@font-face {
  font-family: 'Roboto';
  src: url('/fonts/Roboto-Regular.woff2') format('woff2'), url('/fonts/Roboto-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url('/fonts/Roboto-Bold.woff2') format('woff2'), url('/fonts/Roboto-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
.checkbox {
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  user-select: none;
  letter-spacing: .03em;
}
@media (min-width: 544px) {
  .checkbox {
    font-size: 16px;
  }
}
.checkbox,
.checkbox * {
  color: white;
}
.checkbox__field {
  position: absolute;
  left: -9999px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.checkbox__field::-ms-check {
  /* IE 8 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  /* IE 5-7 */
  filter: alpha(opacity=0);
  /* Good browsers :) */
  opacity: 0;
}
.checkbox__icon {
  position: relative;
  display: block;
  align-self: flex-start;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  margin-right: 7px;
  border: 2px solid white;
  background-color: transparent;
}
.checkbox__icon:before {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 12px;
  height: 12px;
  content: '';
  transition: background-color 0.2s;
  transform: translate(-50%, -50%);
  background-color: transparent;
}
.checkbox__field:checked + .checkbox__icon:before {
  background-color: white;
}
.checkbox__field[disabled] + .checkbox__icon {
  pointer-events: none;
  opacity: .5;
}
