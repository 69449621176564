@import "app";
@import "includes/keyframes";

*,
*::before,
*::after {
    box-sizing: inherit;
    outline:    none;
}

* {
    -webkit-appearance:          none;
    -moz-appearance:             none;
    appearance:                  none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}

html {
    box-sizing: border-box;
}

body {
    min-width:      320px;
    overflow-x:     hidden;
    overflow-y:     inherit;
    display:        flex;
    flex-direction: column;
    min-height:     100vh;
    height:         0;
    font-size:      14px;
    color:          white;
    .sans-serif();
    background: rgb(31,18,90);
    background: linear-gradient(321deg, rgba(31,18,90,1) 0%, rgba(83,25,149,1) 22%, rgba(62,70,172,1) 48%, rgba(60,97,188,1) 74%, rgba(24,200,215,1) 100%);
    background-attachment: fixed;

    &.busy {
        .fullscreen {
            position: relative;
            pointer-events: none;

            &:before {
                opacity: .5;
            }
        }
    }

    &.is-freezed {
        .tablet({
            overflow: hidden;
        });
    }
}
