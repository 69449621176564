@import "../app";

.container {
    max-width:      1360px;
    padding-left:   20px;
    padding-right:  20px;
    margin-left:    auto;
    margin-right:   auto;

    &_hd {
        padding-left: 0;
        padding-right: 0;

        .mobile {
            .tabletSmallBack({
                display: none;
            });
        }

        .desktop {
            display: none;

            .tabletSmallBack({
                display: block;
            });
        }
    }

    &_medium {
        max-width: 590px;
    }

    &_compact {
        max-width: 340px;
    }

    &_mt {
        margin-top: 20px;

        .tabletBack({
            margin-top: 40px;
        });
    }

    &_mb {
        margin-bottom: 20px;

        .tabletBack({
            margin-bottom: 40px;
        });
    }

    &_pb {
        padding-bottom: 20px;

        .tabletBack({
            padding-bottom: 40px;
        });
    }
}
