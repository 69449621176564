.border-radius (@radius: 50%) {
    -webkit-border-radius: @radius;
    -moz-border-radius:    @radius;
    border-radius:         @radius;
};

.image (@path) {
    background-image: url("@{imagesPath}/@{path}");
}

.box-shadow (@shadow) {
    -webkit-box-shadow: @shadow;
    -moz-box-shadow:    @shadow;
    box-shadow:         @shadow;
}

.no-select () {
    -webkit-user-select: none;
    -moz-user-select:    none;
    -ms-user-select:     none;
    user-select:         none;
}

.cover () {
    background-repeat:   no-repeat;
    background-size:     cover;
    background-position: center;
}

.contain () {
    background-repeat:   no-repeat;
    background-size:     contain;
    background-position: center;
}

.blur (@r: 5px) {
    -webkit-filter: blur(@r);
    -moz-filter:    blur(@r);
    -o-filter:      blur(@r);
    -ms-filter:     blur(@r);
    filter:         blur(@r);
}

.gradient (@first, @second) {
    background: @first;                                                                                                         /* Old browsers */
    background: -moz-linear-gradient(left, @first 0%, @second 100%);                                                            /* FF3.6-15 */
    background: -webkit-linear-gradient(left, @first 0%,@second 100%);                                                          /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, @first 0%, @second 100%);                                                             /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter:     progid:DXImageTransform.Microsoft.gradient( startColorstr='@{first}', endColorstr='@{second}',GradientType=1 ); /* IE6-9 */
}

.sans-serif () {
    font-family: 'Roboto', sans-serif;
}

.margin-top (@w: 20px;) {
    margin-top: @w;

    &:first-child {
        margin-top: 0;
    }
}

.margin-bottom (@w: 20px;) {
    margin-bottom: @w;

    &:last-child {
        margin-bottom: 0;
    }
}

.margin-right (@w: 20px;) {
    margin-right: @w;

    &:last-child {
        margin-right: 0;
    }
}

.hyphens () {
    -moz-hyphens:    auto;
    -webkit-hyphens: auto;
    -ms-hyphens:     auto;
}

.placeholder (@rules) {
    &::-webkit-input-placeholder {@rules();}
    &::-moz-placeholder          {@rules();}
    &:-moz-placeholder           {@rules();}
    &:-ms-input-placeholder      {@rules();}
}

.aspect-ratio(@width, @height) {
    position: relative;

    &:before {
        display:     block;
        content:     "";
        width:       100%;
        padding-top: (@height / @width) * 100%;
    }
    > .content {
        position: absolute;
        padding:  10px;
        top:      0;
        left:     0;
        right:    0;
        bottom:   0;
        span:before{
            content:"@{width}";
        }

        span:after{
            content:"@{height}";
        }
    }
}

.font-size (@size) {
    font-size:   @size;
    line-height: @size * 1.3;
}

.clearfix() {
	&:after {
		content: '';
		display: block;
		clear: both;
		font-size: 0;
		line-height: 0;
		height: 0;
	}
}