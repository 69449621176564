@media (max-width: 320px + 50px) {
  .media-test {
    display: 'xs';
  }
}
@media (max-width: 544px) {
  .media-test {
    display: 'mobile';
  }
}
@media (max-width: 864px) {
  .media-test {
    display: 'tabletSmall';
  }
}
@media (max-width: 1030px) {
  .media-test {
    display: 'tablet';
  }
}
@media (max-width: 1233px) {
  .media-test {
    display: 'desktopSmall';
  }
}
@media (max-width: 1643px) {
  .media-test {
    display: 'desktop';
  }
}
@font-face {
  font-family: 'Roboto';
  src: url('/fonts/Roboto-Regular.woff2') format('woff2'), url('/fonts/Roboto-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url('/fonts/Roboto-Bold.woff2') format('woff2'), url('/fonts/Roboto-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
.button {
  line-height: 45px;
  display: inline-block;
  box-sizing: border-box;
  height: 45px;
  padding: 0 10px;
  cursor: pointer;
  user-select: none;
  transition: opacity 0.2s, background-color 0.2s, border-top-color 0.2s, border-bottom-color 0.2s, border-left-color 0.2s, border-right-color 0.2s;
  text-align: center;
  vertical-align: top;
  white-space: nowrap;
  text-decoration: none;
  color: white;
  border: 1px solid white;
  border-radius: 5px;
  background-color: transparent;
}
.button[disabled] {
  transition: opacity .3s;
  pointer-events: none;
  opacity: .5;
}
.button:hover {
  border: 1px solid rgba(255, 255, 255, 0.3);
  background-color: rgba(255, 255, 255, 0.1);
}
.button_main {
  border: 1px solid #27A8CE;
  background-color: #27A8CE;
}
.button_main:hover {
  opacity: .5;
  border: 1px solid #27A8CE;
  background-color: #27A8CE;
}
.button_mainless:hover {
  border: 1px solid #27A8CE;
  background-color: #27A8CE;
}
.button_small {
  line-height: 30px;
  height: 30px;
}
@media (min-width: 544px) {
  .button_small {
    line-height: 35px;
    height: 35px;
  }
}
.button_full {
  display: block;
  width: 100%;
}
.button_done {
  cursor: auto;
  pointer-events: none;
  border-color: transparent;
}
