@import "../app";

.datepicker {
    &--pointer {
        top: auto !important;
        bottom: 1px !important;
        right: 8px !important;
        transform: rotate(135deg) translateX(100%) !important;
    }
}
