@import "../app";

.header {

    &__container {
        position: relative;
        padding-left: 0;
        padding-right: 0;

        .tabletBack({
            display: flex;
            justify-content: flex-end;
            height: @headerHeight;
            padding: 30px 20px;
        });
    }

    &__logo {
        width: 130px;
        height: 78px;
        margin: 20px auto;

        .mobileBack({
            width: 220px;
            height: 132px;
        });

        .tabletBack({
            margin-top: 0;
            margin-bottom: 0;
            position: absolute;
            top: 30px;
            left: 50%;
            transform: translateX(-50%);
        });
    }

    &__background {
        display: flex;
        align-self: flex-start;
        background: rgba(0, 0, 0, 0.11);
        padding: 10px;
        width: 100%;

        .mobileBack({
            justify-content: flex-end;
        });

        .tabletBack({
            padding: 0;
            background: transparent;
        });
    }

    &__grow {
        .mobileBack({
            flex-grow: 1;
        });
    }

    &__user {
        display: flex;
        justify-content: space-between;
        position: relative;
        padding-left: 50px;
        width: 100%;
        flex-shrink: 0;

        &:before {
            display: block;
            position: absolute;
            content: '';
            width: 30px;
            height: 30px;
            top: 0;
            left: 0;
            .contain();
            .image('icons/user.svg');

            .mobileBack({
                width: 35px;
                height: 35px;
            });
        }

        .mobileBack({
            width: auto;
        });
    }

    &__profile {
        width: min-intrinsic;
        width: -webkit-min-content;
        width: -moz-min-content;
        width: min-content;
        display: table-caption;
        display: -ms-grid;
        -ms-grid-columns: min-content;
        font-size: 13px;
        line-height: 15px;
        letter-spacing: 0.03em;
        color: white;
        transition: opacity .2s;

        &:hover {
            opacity: .5;
            text-decoration: none;
        }

        .mobileBack({
            padding-top: 1px;
            line-height: 16px;
        });
    }

    &__logout {
        margin-left: 20px;
        min-width: 70px;
    }
}
