@import '../app';

.set
{
    position: relative;

    max-width: 425px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 5px;
    padding-left: 40px;

    &:before
    {
        position: absolute;
        top: 0;
        left: 0;

        display: block;

        width: 30px;
        height: 30px;

        content: '';

        .contain();
        .image('icons/question.svg');
    }

    &_hidden
    {
        display: none;
    }

    &__text
    {
        font-size: 18px;
        font-weight: bold;
        line-height: 20px;

        margin: 0;

        color: white;
    }

    &__item
    {
        margin-top: 30px;
    }
}
