@import '../app';

.button
{
    line-height: @buttonSize;

    display: inline-block;

    box-sizing: border-box;
    height: @buttonSize;
    padding: 0 10px;

    cursor: pointer;
    user-select: none;
    transition: opacity .2s, background-color .2s, border-top-color .2s, border-bottom-color .2s, border-left-color .2s, border-right-color .2s;
    text-align: center;
    vertical-align: top;
    white-space: nowrap;
    text-decoration: none;

    color: white;
    border: 1px solid white;
    border-radius: 5px;
    background-color: transparent;

    &[disabled]
    {
        transition: opacity .3s;
        pointer-events: none;

        opacity: .5;
    }

    &:hover
    {
        border: 1px solid rgba(255, 255, 255, .3);
        background-color: rgba(255, 255, 255, .1);
    }

    &_main
    {
        border: 1px solid @cyan;
        background-color: @cyan;

        &:hover
        {
            opacity: .5;
            border: 1px solid @cyan;
            background-color: @cyan;
        }
    }

    &_mainless
    {
        &:hover
        {
            border: 1px solid @cyan;
            background-color: @cyan;
        }
    }

    &_small
    {
        line-height: @buttonSizeSmallMobile;

        height: @buttonSizeSmallMobile;

        .mobileBack({
            line-height: @buttonSizeSmall;

            height: @buttonSizeSmall;
        });
    }

    &_full
    {
        display: block;

        width: 100%;
    }

    &_done
    {
        cursor: auto;
        pointer-events: none;

        border-color: transparent;
    }
}
