@media (max-width: 320px + 50px) {
  .media-test {
    display: 'xs';
  }
}
@media (max-width: 544px) {
  .media-test {
    display: 'mobile';
  }
}
@media (max-width: 864px) {
  .media-test {
    display: 'tabletSmall';
  }
}
@media (max-width: 1030px) {
  .media-test {
    display: 'tablet';
  }
}
@media (max-width: 1233px) {
  .media-test {
    display: 'desktopSmall';
  }
}
@media (max-width: 1643px) {
  .media-test {
    display: 'desktop';
  }
}
@font-face {
  font-family: 'Roboto';
  src: url('/fonts/Roboto-Regular.woff2') format('woff2'), url('/fonts/Roboto-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url('/fonts/Roboto-Bold.woff2') format('woff2'), url('/fonts/Roboto-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
.auth__form {
  padding-top: 10px;
}
.auth__button {
  margin-top: 10px;
}
.auth__button_main {
  margin-top: 30px;
}
.auth__button_send {
  margin-top: 60px;
}
.auth__button_save {
  min-width: 150px;
  margin-left: 10px;
  margin-right: 10px;
}
.auth__button_test {
  margin-top: 80px;
  min-width: 280px;
  margin-left: 10px;
  margin-right: 10px;
}
.auth__save {
  text-align: center;
}
.auth__input {
  margin-top: 25px;
}
.auth__input_agree {
  margin-top: 20px;
  font-size: 13px;
}
.auth__text_reset {
  margin-top: 20px;
}
.auth__text_thanks {
  margin-top: 20px;
  font-size: 14px;
  line-height: 20px;
}
.auth__title_thanks {
  margin-top: 20px;
}
.auth__title_section {
  margin-top: 20px;
  max-width: 425px;
  margin-left: auto;
  margin-right: auto;
}
.auth__line {
  margin-top: 30px;
}
@media (min-width: 544px) {
  .auth__line {
    margin-top: 50px;
  }
}
.auth__line_first {
  margin-top: 20px;
}
.auth__set {
  margin-top: 40px;
}
