@media (max-width: 320px + 50px) {
  .media-test {
    display: 'xs';
  }
}
@media (max-width: 544px) {
  .media-test {
    display: 'mobile';
  }
}
@media (max-width: 864px) {
  .media-test {
    display: 'tabletSmall';
  }
}
@media (max-width: 1030px) {
  .media-test {
    display: 'tablet';
  }
}
@media (max-width: 1233px) {
  .media-test {
    display: 'desktopSmall';
  }
}
@media (max-width: 1643px) {
  .media-test {
    display: 'desktop';
  }
}
@font-face {
  font-family: 'Roboto';
  src: url('/fonts/Roboto-Regular.woff2') format('woff2'), url('/fonts/Roboto-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url('/fonts/Roboto-Bold.woff2') format('woff2'), url('/fonts/Roboto-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
.main__container_home {
  max-width: 710px;
}
.main__container_preview {
  text-align: center;
}
.main__tile {
  margin-top: 10px;
}
@media (min-width: 864px) {
  .main__tile {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}
.main__item {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid white;
  border-radius: 10px;
  overflow: hidden;
  transition: border-color 0.2s;
  width: 100%;
  max-width: 315px;
  color: white;
  text-align: center;
}
@media (min-width: 864px) {
  .main__item {
    margin-left: 10px;
    margin-right: 10px;
  }
}
.main__item:after {
  display: block;
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  background: linear-gradient(154.09deg, #1EBDD3 14.23%, #79BFC9 48.52%, #9B6CFF 85.78%);
  transition: opacity .2s;
  z-index: -1;
}
.main__item:hover {
  border-color: transparent;
}
.main__item:hover:after {
  opacity: 1;
}
.main__item_ended:hover {
  border: 1px solid white;
}
.main__item_ended:hover:after {
  opacity: 0;
}
.main__cover {
  position: relative;
  height: 100px;
}
.main__cover_test {
  margin-top: 40px;
}
.main__logo {
  display: block;
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  height: 60%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.main__logo_test {
  width: 80%;
  height: 80%;
}
.main__name {
  margin: 0;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
}
.main__description {
  margin: 0;
  margin-top: 15px;
  font-size: 14px;
  line-height: 20px;
  flex-grow: 1;
}
.main__button {
  margin-top: 40px;
  min-width: 193px;
}
.main__button_begin {
  min-width: 200px;
  margin-top: 40px;
}
.main__button_next {
  min-width: 145px;
  margin-top: 30px;
}
.main__button_finish {
  min-width: 200px;
  margin-top: 80px;
}
.main__information {
  margin: 0;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 65px;
  opacity: .5;
  font-size: 12px;
  line-height: 14px;
}
.main__text_preview {
  margin-top: 30px;
}
.main__line {
  margin-top: 40px;
  max-width: 455px;
  margin-left: auto;
  margin-right: auto;
}
.main__set {
  margin-top: 40px;
  max-width: 500px;
}
.main__set:first-child {
  margin-top: 0;
}
.main__field {
  margin-left: 0;
}
.main__note {
  margin-top: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  opacity: .3;
  text-align: center;
  font-size: 10px;
}
