@media (max-width: 320px + 50px) {
  .media-test {
    display: 'xs';
  }
}
@media (max-width: 544px) {
  .media-test {
    display: 'mobile';
  }
}
@media (max-width: 864px) {
  .media-test {
    display: 'tabletSmall';
  }
}
@media (max-width: 1030px) {
  .media-test {
    display: 'tablet';
  }
}
@media (max-width: 1233px) {
  .media-test {
    display: 'desktopSmall';
  }
}
@media (max-width: 1643px) {
  .media-test {
    display: 'desktop';
  }
}
@font-face {
  font-family: 'Roboto';
  src: url('/fonts/Roboto-Regular.woff2') format('woff2'), url('/fonts/Roboto-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url('/fonts/Roboto-Bold.woff2') format('woff2'), url('/fonts/Roboto-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
.tabs {
  position: relative;
  max-width: 425px;
  margin-top: 14px;
  margin-right: auto;
  margin-left: auto;
}
.tabs__label {
  font-size: 10px;
  line-height: 10px;
  display: block;
  margin-bottom: 5px;
  padding-left: 20px;
  opacity: .7;
  color: white;
  font-family: 'Roboto', sans-serif;
}
.tabs__inner {
  display: flex;
  height: 45px;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  background-color: transparent;
}
.tabs_error .tabs__inner {
  border: 1px solid #FD4040;
}
.tabs__input:checked + .tabs__item {
  background-color: rgba(255, 255, 255, 0.3) !important;
}
.tabs__input {
  position: absolute;
  left: -9999px;
}
.tabs__item {
  display: flex;
  overflow: hidden;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  padding: 0 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  text-align: center;
  border-right: 1px solid rgba(255, 255, 255, 0.3);
  background-color: transparent;
}
.tabs__item:last-child {
  border-right: none;
}
