@media (max-width: 320px + 50px) {
  .media-test {
    display: 'xs';
  }
}
@media (max-width: 544px) {
  .media-test {
    display: 'mobile';
  }
}
@media (max-width: 864px) {
  .media-test {
    display: 'tabletSmall';
  }
}
@media (max-width: 1030px) {
  .media-test {
    display: 'tablet';
  }
}
@media (max-width: 1233px) {
  .media-test {
    display: 'desktopSmall';
  }
}
@media (max-width: 1643px) {
  .media-test {
    display: 'desktop';
  }
}
@font-face {
  font-family: 'Roboto';
  src: url('/fonts/Roboto-Regular.woff2') format('woff2'), url('/fonts/Roboto-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url('/fonts/Roboto-Bold.woff2') format('woff2'), url('/fonts/Roboto-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
.header__container {
  position: relative;
  padding-left: 0;
  padding-right: 0;
}
@media (min-width: 1030px) {
  .header__container {
    display: flex;
    justify-content: flex-end;
    height: 192px;
    padding: 30px 20px;
  }
}
.header__logo {
  width: 130px;
  height: 78px;
  margin: 20px auto;
}
@media (min-width: 544px) {
  .header__logo {
    width: 220px;
    height: 132px;
  }
}
@media (min-width: 1030px) {
  .header__logo {
    margin-top: 0;
    margin-bottom: 0;
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
  }
}
.header__background {
  display: flex;
  align-self: flex-start;
  background: rgba(0, 0, 0, 0.11);
  padding: 10px;
  width: 100%;
}
@media (min-width: 544px) {
  .header__background {
    justify-content: flex-end;
  }
}
@media (min-width: 1030px) {
  .header__background {
    padding: 0;
    background: transparent;
  }
}
@media (min-width: 544px) {
  .header__grow {
    flex-grow: 1;
  }
}
.header__user {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding-left: 50px;
  width: 100%;
  flex-shrink: 0;
}
.header__user:before {
  display: block;
  position: absolute;
  content: '';
  width: 30px;
  height: 30px;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url("/img/icons/user.svg");
}
@media (min-width: 544px) {
  .header__user:before {
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 544px) {
  .header__user {
    width: auto;
  }
}
.header__profile {
  width: min-intrinsic;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  display: table-caption;
  display: -ms-grid;
  -ms-grid-columns: min-content;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.03em;
  color: white;
  transition: opacity .2s;
}
.header__profile:hover {
  opacity: .5;
  text-decoration: none;
}
@media (min-width: 544px) {
  .header__profile {
    padding-top: 1px;
    line-height: 16px;
  }
}
.header__logout {
  margin-left: 20px;
  min-width: 70px;
}
