@import "../app";

.main {

    &__container {
        &_home {
            max-width: 710px;
        }

        &_preview {
            text-align: center;
        }
    }

    &__tile {
        margin-top: 10px;

        .tabletSmallBack({
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        });
    }

    &__item {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
        border: 1px solid white;
        border-radius: 10px;
        overflow: hidden;
        transition: border-color .2s;
        width: 100%;
        max-width: 315px;
        color: white;
        text-align: center;

        .tabletSmallBack({
            margin-left: 10px;
            margin-right: 10px;
        });

        &:after {
            display: block;
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            opacity: 0;
            background: linear-gradient(154.09deg, #1EBDD3 14.23%, #79BFC9 48.52%, #9B6CFF 85.78%);
            transition: opacity .2s;
            z-index: -1;
        }

        &:hover {
            border-color: transparent;

            &:after {
                opacity: 1;
            }
        }

        &_ended {
            &:hover {
                border: 1px solid white;

                &:after {
                    opacity: 0;
                }
            }
        }
    }

    &__cover {
        position: relative;
        height: 100px;

        &_test {
            margin-top: 40px;
        }
    }

    &__logo {
        display: block;
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 60%;
        height: 60%;
        .contain();

        &_test {
            width: 80%;
            height: 80%;
        }
    }

    &__name {
        margin: 0;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
    }

    &__description {
        margin: 0;
        margin-top: 15px;
        font-size: 14px;
        line-height: 20px;
        flex-grow: 1;
    }

    &__button {
        margin-top: 40px;
        min-width: 193px;

        &_begin {
            min-width: 200px;
            margin-top: 40px;
        }

        &_next {
            min-width: 145px;
            margin-top: 30px;
        }

        &_finish {
            min-width: 200px;
            margin-top: 80px;
        }
    }

    &__information {
        margin: 0;
        padding-top: 5px;
        padding-bottom: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 65px;
        opacity: .5;
        font-size: 12px;
        line-height: 14px;
    }

    &__text {
        &_preview {
            margin-top: 30px;
        }
    }

    &__line {
        margin-top: 40px;
        max-width: 455px;
        margin-left: auto;
        margin-right: auto;
    }

    &__set {
        margin-top: 40px;
        max-width: 500px;

        &:first-child {
            margin-top: 0;
        }
    }

    &__field {
        margin-left: 0;
    }

    &__note {
        margin-top: auto;
        padding-top: 20px;
        padding-bottom: 20px;
        opacity: .3;
        text-align: center;
        font-size: 10px;
    }

}
