@media (max-width: 320px + 50px) {
  .media-test {
    display: 'xs';
  }
}
@media (max-width: 544px) {
  .media-test {
    display: 'mobile';
  }
}
@media (max-width: 864px) {
  .media-test {
    display: 'tabletSmall';
  }
}
@media (max-width: 1030px) {
  .media-test {
    display: 'tablet';
  }
}
@media (max-width: 1233px) {
  .media-test {
    display: 'desktopSmall';
  }
}
@media (max-width: 1643px) {
  .media-test {
    display: 'desktop';
  }
}
@font-face {
  font-family: 'Roboto';
  src: url('/fonts/Roboto-Regular.woff2') format('woff2'), url('/fonts/Roboto-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url('/fonts/Roboto-Bold.woff2') format('woff2'), url('/fonts/Roboto-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
.title {
  margin: 0;
  font-size: 16px;
  line-height: 19px;
  color: white;
  text-align: center;
  font-weight: normal;
}
@media (min-width: 544px) {
  .title {
    font-size: 20px;
    line-height: 23px;
  }
}
.title_subtitle {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
}
@media (min-width: 544px) {
  .title_subtitle {
    font-size: 18px;
    line-height: 21px;
  }
}
.title_section {
  font-size: 14px;
  line-height: 16px;
  text-align: left;
}
@media (min-width: 544px) {
  .title_section {
    font-size: 16px;
    line-height: 19px;
  }
}
