@import "../app";

.title {
    margin: 0;
    font-size: 16px;
    line-height: 19px;
    color: white;
    text-align: center;
    font-weight: normal;

    .mobileBack({
        font-size: 20px;
        line-height: 23px;
    });

    &_subtitle {
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;

        .mobileBack({
            font-size: 18px;
            line-height: 21px;
        });
    }

    &_section {
        font-size: 14px;
        line-height: 16px;
        text-align: left;

        .mobileBack({
            font-size: 16px;
            line-height: 19px;
        });
    }
}
