@import '../app';

.checkbox
{
    font-size: 14px;
    line-height: 20px;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    cursor: pointer;
    user-select: none;
    letter-spacing: .03em;

    .mobileBack({
        font-size: 16px;
    });

    &,
    *
    {
        color: white;
    }

    &__field
    {
        position: absolute;
        left:     -9999px;

        -webkit-appearance: none;
           -moz-appearance: none;
                appearance: none;

        &::-ms-check {
            /* IE 8 */
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
            /* IE 5-7 */
            filter: alpha(opacity=0);
            /* Good browsers :) */
            opacity:0;
        }
    }

    &__icon
    {
        position: relative;

        display: block;
        align-self: flex-start;
        flex-shrink: 0;

        width: 20px;
        height: 20px;
        margin-right: 7px;

        border: 2px solid white;
        background-color: transparent;

        &:before
        {
            position: absolute;
            top: 50%;
            left: 50%;

            display: block;

            width: 12px;
            height: 12px;

            content: '';
            transition: background-color .2s;
            transform: translate(-50%, -50%);

            background-color: transparent;
        }
    }

    &__field:checked + &__icon
    {
        &:before
        {
            background-color: white;
        }
    }

    &__field[disabled] + &__icon
    {
        pointer-events: none;

        opacity: .5;
    }
}
