@import '../app';

.input
{
    position: relative;

    max-width: 425px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 5px;

    &_disabled
    {
        cursor: auto;
        user-select: none;
        pointer-events: none;

        opacity: .7;
    }

    &_select
    {
        &:before
        {
            position: absolute;
            top: 18px;
            right: 14px;

            display: block;

            width: 18px;
            height: 18px;

            content: '';
            user-select: none;
            transition: transform .2s;
            pointer-events: none;

            background-size: 75% !important;

            .contain();
            .image('icons/arrow.svg');
        }
    }

    &_focus
    {
        &:before
        {
            transform: rotate(180deg);
        }
    }

    &_date
    {
        &:before
        {
            position: absolute;
            top: 17px;
            right: 12px;

            display: block;

            width: 20px;
            height: 20px;

            content: '';
            user-select: none;
            pointer-events: none;

            .contain();
            .image('icons/cal.svg');
        }
    }

    &__password
    {
        position: absolute;
        top: 18px;
        right: 11px;

        width: 20px;
        height: 20px;

        transition: opacity .2s;
        pointer-events: none;

        opacity: 0;

        .contain();
        .image('icons/password.svg');

        &:before
        {
            position: absolute;
            top: 0;
            left: 0;

            display: block;

            width: 100%;
            height: 100%;

            content: '';
            transition: opacity .2s;

            .contain();
            .image('icons/password_line.svg');
        }

        &_show
        {
            &:before
            {
                opacity: 0;
            }
        }
    }

    &__label
    {
        font-size: 13px;
        line-height: 45px;

        position: absolute;
        top: 5px;
        right: 3px;
        left: 0;

        display: flex;
        align-items: center;

        padding-left: 3px;

        user-select: none;
        transition: font-size .1s, top .2s, line-height .1s, opacity .2s;
        white-space: nowrap;
        letter-spacing: .05em;
        pointer-events: none;

        opacity: .7;
        color: white;
        background-color: inherit;

        .sans-serif();
    }

    &_error &__label
    {
        color: @error;
    }

    &__left,
    &__right
    {
        position: relative;

        display: block;
        visibility: hidden;

        height: 1px;

        transition: visibility 0s;

        opacity: .3;
        background-color: white;

        &:before,
        &:after
        {
            position: absolute;

            visibility: hidden;

            width: 1px;
            height: 1px;

            content: '';
            transition: visibility 0s;

            background-color: white;
        }

        &:before
        {
            bottom: -1px;
        }

        &:after
        {
            bottom: 0;

            opacity: .5;
        }
    }

    &__left
    {
        flex-shrink: 0;

        width: 12px;
        margin-right: 3px;

        &:before,
        &:after
        {
            left: -1px;
        }
    }

    &__right
    {
        flex-grow: 1;

        width: 100%;
        margin-left: 3px;

        &:before,
        &:after
        {
            right: -1px;
        }
    }

    &_error &__left,
    &_error &__right
    {
        opacity: 1;
        background-color: @error;

        &:before,
        &:after
        {
            background-color: @error;
        }
    }

    &__field
    {
        font-size: 13px;
        line-height: 13px;

        width: 100%;
        height: 45px;
        padding: 16px 15px;

        transition-delay: 0s;
        letter-spacing: .05em;

        color: white;
        border: 1px solid rgba(255, 255, 255, .3);
        border-radius: 5px;
        background-color: transparent;

        .sans-serif();

        &_textarea
        {
            font-size: 14px;
            line-height: 19px;

            display: block;
            overflow-y: hidden;

            height: auto;
            padding-top: 12px;
            padding-bottom: 12px;

            resize: none;
        }

        &::placeholder
        {
            transition: opacity .2s;

            opacity: 0;
            color: inherit;
        }

        &::-webkit-scrollbar
        {
            display: none;
        }

        &:focus,
        &:valid
        {
            transition-delay: .2s;

            border-top-color: transparent !important;
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active
        {
            -webkit-transition: color 9999s ease-out, background-color 9999s ease-out;
            -webkit-transition-delay: 9999s;
        }
    }

    &_active &__field
    {
        transition-delay: .2s;

        border-top-color: transparent !important;
    }

    &_error &__field
    {
        color: @error;
        border: 1px solid @error;
    }

    &__text
    {
        overflow: hidden;
        flex-shrink: 0;

        max-width: 90%;

        white-space: nowrap;
    }

    &_select &__field
    {
        cursor: pointer;
    }

    &__list
    {
        font-size: 13px;
        line-height: 20px;

        position: absolute;
        z-index: @layerDefault;
        top: 100%;
        left: 0;

        display: none;
        overflow-x: hidden;
        overflow-y: auto;

        width: 100%;

        user-select: none;

        color: #333;
        border-radius: 5px;
        background-color: white;
        box-shadow: 0 2px 2px rgba(0, 0, 0, .24), 0 0 2px rgba(0, 0, 0, .12);
    }

    &__item
    {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 14px;

        cursor: pointer;
        transition: background-color .2s;

        &:hover
        {
            background: #f7f7f7;
        }
    }

    &_active &__label,
    &_active &__label,
    &__field:focus + &__label,
    &__field:valid + &__label
    {
        font-size: 10px;
        line-height: 10px;

        top: 0;
        left: 0;

        opacity: 1;
    }

    &_active &__field,
    &_active &__field,
    &__field:focus,
    &__field:valid
    {
        &::placeholder
        {
            opacity: .5;
        }
    }

    &_active &__left,
    &_active &__right,
    &__field:focus + &__label > &__left,
    &__field:focus + &__label > &__right,
    &__field:valid + &__label > &__left,
    &__field:valid + &__label > &__right
    {
        visibility: visible;

        transition-delay: .2s;

        &:before,
        &:after
        {
            visibility: visible;

            transition-delay: .2s;
        }
    }

    &__field:valid ~ &__password
    {
        cursor: pointer;
        pointer-events: auto;

        opacity: 1;
    }

    &__message
    {
        font-size: 13px;
        line-height: 16px;

        max-width: 425px;
        margin-top: 4px;
        margin-right: auto;
        margin-left: auto;

        transition: height .2s;

        color: @error;
    }
}
