@media (max-width: 320px + 50px) {
  .media-test {
    display: 'xs';
  }
}
@media (max-width: 544px) {
  .media-test {
    display: 'mobile';
  }
}
@media (max-width: 864px) {
  .media-test {
    display: 'tabletSmall';
  }
}
@media (max-width: 1030px) {
  .media-test {
    display: 'tablet';
  }
}
@media (max-width: 1233px) {
  .media-test {
    display: 'desktopSmall';
  }
}
@media (max-width: 1643px) {
  .media-test {
    display: 'desktop';
  }
}
@font-face {
  font-family: 'Roboto';
  src: url('/fonts/Roboto-Regular.woff2') format('woff2'), url('/fonts/Roboto-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url('/fonts/Roboto-Bold.woff2') format('woff2'), url('/fonts/Roboto-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
.input {
  position: relative;
  max-width: 425px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 5px;
}
.input_disabled {
  cursor: auto;
  user-select: none;
  pointer-events: none;
  opacity: .7;
}
.input_select:before {
  position: absolute;
  top: 18px;
  right: 14px;
  display: block;
  width: 18px;
  height: 18px;
  content: '';
  user-select: none;
  transition: transform .2s;
  pointer-events: none;
  background-size: 75% !important;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url("/img/icons/arrow.svg");
}
.input_focus:before {
  transform: rotate(180deg);
}
.input_date:before {
  position: absolute;
  top: 17px;
  right: 12px;
  display: block;
  width: 20px;
  height: 20px;
  content: '';
  user-select: none;
  pointer-events: none;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url("/img/icons/cal.svg");
}
.input__password {
  position: absolute;
  top: 18px;
  right: 11px;
  width: 20px;
  height: 20px;
  transition: opacity .2s;
  pointer-events: none;
  opacity: 0;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url("/img/icons/password.svg");
}
.input__password:before {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  content: '';
  transition: opacity .2s;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url("/img/icons/password_line.svg");
}
.input__password_show:before {
  opacity: 0;
}
.input__label {
  font-size: 13px;
  line-height: 45px;
  position: absolute;
  top: 5px;
  right: 3px;
  left: 0;
  display: flex;
  align-items: center;
  padding-left: 3px;
  user-select: none;
  transition: font-size 0.1s, top 0.2s, line-height 0.1s, opacity 0.2s;
  white-space: nowrap;
  letter-spacing: .05em;
  pointer-events: none;
  opacity: .7;
  color: white;
  background-color: inherit;
  font-family: 'Roboto', sans-serif;
}
.input_error .input__label {
  color: #FD4040;
}
.input__left,
.input__right {
  position: relative;
  display: block;
  visibility: hidden;
  height: 1px;
  transition: visibility 0s;
  opacity: .3;
  background-color: white;
}
.input__left:before,
.input__right:before,
.input__left:after,
.input__right:after {
  position: absolute;
  visibility: hidden;
  width: 1px;
  height: 1px;
  content: '';
  transition: visibility 0s;
  background-color: white;
}
.input__left:before,
.input__right:before {
  bottom: -1px;
}
.input__left:after,
.input__right:after {
  bottom: 0;
  opacity: .5;
}
.input__left {
  flex-shrink: 0;
  width: 12px;
  margin-right: 3px;
}
.input__left:before,
.input__left:after {
  left: -1px;
}
.input__right {
  flex-grow: 1;
  width: 100%;
  margin-left: 3px;
}
.input__right:before,
.input__right:after {
  right: -1px;
}
.input_error .input__left,
.input_error .input__right {
  opacity: 1;
  background-color: #FD4040;
}
.input_error .input__left:before,
.input_error .input__right:before,
.input_error .input__left:after,
.input_error .input__right:after {
  background-color: #FD4040;
}
.input__field {
  font-size: 13px;
  line-height: 13px;
  width: 100%;
  height: 45px;
  padding: 16px 15px;
  transition-delay: 0s;
  letter-spacing: .05em;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  background-color: transparent;
  font-family: 'Roboto', sans-serif;
}
.input__field_textarea {
  font-size: 14px;
  line-height: 19px;
  display: block;
  overflow-y: hidden;
  height: auto;
  padding-top: 12px;
  padding-bottom: 12px;
  resize: none;
}
.input__field::placeholder {
  transition: opacity .2s;
  opacity: 0;
  color: inherit;
}
.input__field::-webkit-scrollbar {
  display: none;
}
.input__field:focus,
.input__field:valid {
  transition-delay: .2s;
  border-top-color: transparent !important;
}
.input__field:-webkit-autofill,
.input__field:-webkit-autofill:hover,
.input__field:-webkit-autofill:focus,
.input__field:-webkit-autofill:active {
  -webkit-transition: color 9999s ease-out, background-color 9999s ease-out;
  -webkit-transition-delay: 9999s;
}
.input_active .input__field {
  transition-delay: .2s;
  border-top-color: transparent !important;
}
.input_error .input__field {
  color: #FD4040;
  border: 1px solid #FD4040;
}
.input__text {
  overflow: hidden;
  flex-shrink: 0;
  max-width: 90%;
  white-space: nowrap;
}
.input_select .input__field {
  cursor: pointer;
}
.input__list {
  font-size: 13px;
  line-height: 20px;
  position: absolute;
  z-index: 10;
  top: 100%;
  left: 0;
  display: none;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  user-select: none;
  color: #333;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24), 0 0 2px rgba(0, 0, 0, 0.12);
}
.input__item {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 14px;
  cursor: pointer;
  transition: background-color 0.2s;
}
.input__item:hover {
  background: #f7f7f7;
}
.input_active .input__label,
.input_active .input__label,
.input__field:focus + .input__label,
.input__field:valid + .input__label {
  font-size: 10px;
  line-height: 10px;
  top: 0;
  left: 0;
  opacity: 1;
}
.input_active .input__field::placeholder,
.input_active .input__field::placeholder,
.input__field:focus::placeholder,
.input__field:valid::placeholder {
  opacity: .5;
}
.input_active .input__left,
.input_active .input__right,
.input__field:focus + .input__label > .input__left,
.input__field:focus + .input__label > .input__right,
.input__field:valid + .input__label > .input__left,
.input__field:valid + .input__label > .input__right {
  visibility: visible;
  transition-delay: .2s;
}
.input_active .input__left:before,
.input_active .input__right:before,
.input__field:focus + .input__label > .input__left:before,
.input__field:focus + .input__label > .input__right:before,
.input__field:valid + .input__label > .input__left:before,
.input__field:valid + .input__label > .input__right:before,
.input_active .input__left:after,
.input_active .input__right:after,
.input__field:focus + .input__label > .input__left:after,
.input__field:focus + .input__label > .input__right:after,
.input__field:valid + .input__label > .input__left:after,
.input__field:valid + .input__label > .input__right:after {
  visibility: visible;
  transition-delay: .2s;
}
.input__field:valid ~ .input__password {
  cursor: pointer;
  pointer-events: auto;
  opacity: 1;
}
.input__message {
  font-size: 13px;
  line-height: 16px;
  max-width: 425px;
  margin-top: 4px;
  margin-right: auto;
  margin-left: auto;
  transition: height .2s;
  color: #FD4040;
}
