@media (max-width: 320px + 50px) {
  .media-test {
    display: 'xs';
  }
}
@media (max-width: 544px) {
  .media-test {
    display: 'mobile';
  }
}
@media (max-width: 864px) {
  .media-test {
    display: 'tabletSmall';
  }
}
@media (max-width: 1030px) {
  .media-test {
    display: 'tablet';
  }
}
@media (max-width: 1233px) {
  .media-test {
    display: 'desktopSmall';
  }
}
@media (max-width: 1643px) {
  .media-test {
    display: 'desktop';
  }
}
@font-face {
  font-family: 'Roboto';
  src: url('/fonts/Roboto-Regular.woff2') format('woff2'), url('/fonts/Roboto-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url('/fonts/Roboto-Bold.woff2') format('woff2'), url('/fonts/Roboto-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
.container {
  max-width: 1360px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
}
.container_hd {
  padding-left: 0;
  padding-right: 0;
}
@media (min-width: 864px) {
  .container_hd .mobile {
    display: none;
  }
}
.container_hd .desktop {
  display: none;
}
@media (min-width: 864px) {
  .container_hd .desktop {
    display: block;
  }
}
.container_medium {
  max-width: 590px;
}
.container_compact {
  max-width: 340px;
}
.container_mt {
  margin-top: 20px;
}
@media (min-width: 1030px) {
  .container_mt {
    margin-top: 40px;
  }
}
.container_mb {
  margin-bottom: 20px;
}
@media (min-width: 1030px) {
  .container_mb {
    margin-bottom: 40px;
  }
}
.container_pb {
  padding-bottom: 20px;
}
@media (min-width: 1030px) {
  .container_pb {
    padding-bottom: 40px;
  }
}
