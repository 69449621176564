@import "../app";

.link {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;
    text-align: center;
    font-size: 13px;
    line-height: 18px;
    min-height: 42px;
    color: white;
    transition: opacity .2s;

    &:hover {
        opacity: .5;
        text-decoration: none;
    }
}
