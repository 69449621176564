@import "../app";

.radio {
    display:         flex;
    align-items:     center;
    justify-content: flex-start;
    color:           white;
    cursor:          pointer;
    user-select:     none;
    font-size:       14px;
    line-height:     20px;
    letter-spacing:  0.03em;

    .mobileBack({
        font-size: 16px;
    });

    &__field {
        -webkit-appearance: none;
        -moz-appearance:    none;
        appearance:         none;
        position:           absolute;
        left:               -9999px;

        &::-ms-check {
            /* IE 8 */
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
            /* IE 5-7 */
            filter: alpha(opacity=0);
            /* Good browsers :) */
            opacity:0;
        }
    }

    &__icon {
        align-self:       flex-start;
        position:         relative;
        background-color: transparent;
        display:          block;
        width:            20px;
        height:           20px;
        border:           2px solid white;
        border-radius:    50%;
        margin-right:     7px;
        flex-shrink:      0;

        &:before {
            display: block;
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-color: transparent;
            transition: background-color .2s;
        }
    }

    &__field:checked + &__icon {
        &:before {
            background-color: white;
        }
    }

    &__field[disabled] + &__icon {
        opacity:        .5;
        pointer-events: none;
    }

}
