@media (max-width: 320px + 50px) {
  .media-test {
    display: 'xs';
  }
}
@media (max-width: 544px) {
  .media-test {
    display: 'mobile';
  }
}
@media (max-width: 864px) {
  .media-test {
    display: 'tabletSmall';
  }
}
@media (max-width: 1030px) {
  .media-test {
    display: 'tablet';
  }
}
@media (max-width: 1233px) {
  .media-test {
    display: 'desktopSmall';
  }
}
@media (max-width: 1643px) {
  .media-test {
    display: 'desktop';
  }
}
@font-face {
  font-family: 'Roboto';
  src: url('/fonts/Roboto-Regular.woff2') format('woff2'), url('/fonts/Roboto-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url('/fonts/Roboto-Bold.woff2') format('woff2'), url('/fonts/Roboto-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
.step {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}
@media (min-width: 1030px) {
  .step {
    padding-bottom: 60px;
  }
}
.step__stage {
  margin: 0;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
}
.step__stage_current {
  color: white;
}
.step__line {
  width: 20px;
  height: 1px;
  background-color: white;
}
