@import "../app";

.step {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;

    .tabletBack({
        padding-bottom: 60px;
    });

    &__stage {
        margin: 0;
        padding-left: 20px;
        padding-right: 20px;
        position: relative;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: rgba(255, 255, 255, 0.5);

        &_current {
            color: white;
        }
    }

    &__line {
        width: 20px;
        height: 1px;
        background-color: white;
    }
}
