@import "../app";

.song {
    display: none;
    margin-top: 20px;
    font-size: 14px;
    line-height: 25px;
    color: white;
    letter-spacing: 0.03em;
    text-align: center;

    &__title {
        margin: 0;
        font-weight: bold;
        font-size: 20px;
        line-height: 22px;
    }
}
