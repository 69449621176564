@import "../app";

.player {
    display: flex;
    align-items: center;
    position: relative;
    border-radius: 30px;
    width: 100%;
    padding: 10px;
    padding-right: 20px;
    margin-top: 10px;
    transition: background-color .2s;
    background: rgba(0, 0, 0, 0.1);

    .tabletSmallBack({
        padding-right: 60px;
    });

    &__play {
        margin-right: 16px;
        display: block;
        width: 40px;
        height: 40px;
        border: none;
        background-color: transparent;
        font-size: 0;
        .contain();
        .image('player/play.svg');
        cursor: pointer;
        border-radius: 50%;

        &_active {
            .image('player/pause.svg');
        }
    }

    &__progress {
        position:       relative;
        width:          100%;
        height:         3px;
        border-radius:  3px;
    }

    &__rewind {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        height: 4px;
        z-index: 2;
        cursor: auto;
        user-select: none;
        pointer-events: none;
        overflow: hidden;
        border-radius: 3px;

        &::-webkit-slider-runnable-track {
            background: #E9E9E9;
        }

        /*
         * 1. Set to 0 width and remove border for a slider without a thumb
         */
        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            width: 0; /* 1 */
            height: 4px;
            background: #fff;
            box-shadow: -100vw 0 0 100vw @cyanLight;
            border: none; /* 1 */
        }

        &::-moz-range-track {
            height: 40px;
            background: #E9E9E9;
        }

        &::-moz-range-thumb {
            background: #fff;
            height: 0;
            width: 20px;
            border: none;
            border-radius: 0 !important;
            box-sizing: border-box;
            box-shadow: -100vw 0 0 100vw @cyanLight;
        }

        &::-ms-fill-lower {
            background: @cyanLight;
        }

        &::-ms-thumb {
            background: #fff;
            border: none;
            height: 4px;
            width: 0;
            box-sizing: border-box;
        }

        &::-ms-ticks-after {
            display: none;
        }

        &::-ms-ticks-before {
            display: none;
        }

        &::-ms-track {
            background: #E9E9E9;
            color: transparent;
            height: 4px;
            border: none;
        }

        &::-ms-tooltip {
            display: none;
        }
    }

    &__volume {
        display:       none;
        position:      absolute;
        right:         21px;
        bottom:        18px;
        height:        27px;
        width:         27px;
        border:        1px solid #CBCBCB;
        border-radius: 5px;
        opacity:       .5;
        transition:    height .2s, background-color .2s, border-color .2s, opacity .2s;

        &:hover {
            height:           95px;
            background-color: white;
            border:           1px solid #CBCBCB;
        }

        .tabletSmallBack({
            display: block;
        });
    }

    &:hover &__volume {
        opacity: 1;
    }

    &__button {
        left:                0;
        bottom:              0;
        display:             block;
        border:              none;
        font-size:           0;
        background-color:    transparent;
        cursor:              pointer;
        flex-shrink:         0;
        position:            absolute;
        padding:             0;
        width:               25px;
        height:              25px;
        background-position: center;
        background-size:     15px;
        background-repeat:   no-repeat;
        transition:          opacity .2s;
        .image('player/volume-full-icon.svg');

        &:hover {
            opacity: .8;
        }

        &_medium { .image('player/volume-medium-icon.svg')}
        &_mute   { .image('player/volume-no-icon.svg')}
    }

    &__bar {
        position: relative;
        bottom: 0;
        left: 0;
        overflow: hidden;
        height: 0;
        transition: height .2s;
    }

    &__volume:hover &__bar {
        height: 64px;
    }

    &__fill {
        background: #F4F4F4;
        border-radius: 2px;
        width: 3px;
        height: 56px;
        position: absolute;
        bottom: 0;
        left: 11px;

        &_highlight {
            width: 100%;
            left: 0;
            background: @cyanLight;
        }
    }

    &__controller {
        position:           absolute;
        left:               0;
        bottom:             0;
        width:              100%;
        height:             100%;
        z-index:            2;
        writing-mode:       bt-lr;           /* IE */
        -webkit-appearance: slider-vertical; /* WebKit */
        opacity:            0;
        cursor:             pointer;
    }

}
