@media (max-width: 320px + 50px) {
  .media-test {
    display: 'xs';
  }
}
@media (max-width: 544px) {
  .media-test {
    display: 'mobile';
  }
}
@media (max-width: 864px) {
  .media-test {
    display: 'tabletSmall';
  }
}
@media (max-width: 1030px) {
  .media-test {
    display: 'tablet';
  }
}
@media (max-width: 1233px) {
  .media-test {
    display: 'desktopSmall';
  }
}
@media (max-width: 1643px) {
  .media-test {
    display: 'desktop';
  }
}
@font-face {
  font-family: 'Roboto';
  src: url('/fonts/Roboto-Regular.woff2') format('woff2'), url('/fonts/Roboto-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url('/fonts/Roboto-Bold.woff2') format('woff2'), url('/fonts/Roboto-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
*,
*::before,
*::after {
  box-sizing: inherit;
  outline: none;
}
* {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
html {
  box-sizing: border-box;
}
body {
  min-width: 320px;
  overflow-x: hidden;
  overflow-y: inherit;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 0;
  font-size: 14px;
  color: white;
  font-family: 'Roboto', sans-serif;
  background: #1f125a;
  background: linear-gradient(321deg, #1f125a 0%, #531995 22%, #3e46ac 48%, #3c61bc 74%, #18c8d7 100%);
  background-attachment: fixed;
}
body.busy .fullscreen {
  position: relative;
  pointer-events: none;
}
body.busy .fullscreen:before {
  opacity: .5;
}
@media (max-width: 1030px) {
  body.is-freezed {
    overflow: hidden;
  }
}
