@import '../app';

.text
{
    font-size: 12px;
    line-height: 16px;

    margin: 10px 0 0;

    color: white;

    .sans-serif();
    .mobileBack({
        font-size: 14px;
        line-height: 20px;
    });

    &_left
    {
        &,
        *
        {
            text-align: left;
        }
    }

    &_center
    {
        text-align: center;
    }
}
